import { env } from '@/utils';
import {
  RouteRecordRaw,
  RouteLocation,
  RouteRecordNormalized,
  RouteLocationNormalized,
} from 'vue-router';
import appRoutes from '@/router/routes';

/**
 * Route helper methods.
 */
export class RouteHelper {
  /**
   * Filter the given routes to only contain routes that could be accessed by
   * one of the given roles.
   *
   * @param {RouteRecordNormalized[]} routes
   * @param {String[]} roles
   * @returns {RouteRecordNormalized[]}
   */
  static filterByRoles(
    routes: RouteRecordRaw[],
    roles: string[]
  ): RouteRecordRaw[] {
    return routes.filter((route: RouteRecordRaw) => {
      if (!RouteHelper.hasPermissionToAccess(route, roles)) {
        return false;
      }

      if (!route.children) {
        return true;
      }

      route.children = RouteHelper.filterByRoles(route.children, roles);

      return true;
    });
  }

  /**
   * Determine if one of the given roles has the permission to access the given route.
   *
   * @param {RouteRecordRaw} routeConfig
   * @param {String[]} roles
   * @param permissions
   * @returns {boolean}
   */
  static hasPermissionToAccess(
    routeConfig: RouteRecordRaw | RouteLocationNormalized,
    roles: string[], // tslint:disable-line:trailing-comma
    permissions: string[] = []
  ): boolean {
    if (!RouteHelper.isRouteProtected(routeConfig)) {
      return true;
    }

    if (!routeConfig.meta) {
      return false;
    }

    return (
      (routeConfig.meta?.roles as string[] | undefined)?.some((role) =>
        roles.includes(role)
      ) ||
      (routeConfig.meta?.permissions as string[] | undefined)?.some(
        (permission) => permissions.includes(permission)
      )
    );
  }

  /**
   * Determines whether the route is protected or not.
   *
   * @param {RouteRecordRaw | RouteLocation | RouteRecordNormalized} route
   * @returns {boolean} - true for protected false for not
   */
  static isRouteProtected(
    route: RouteRecordRaw | RouteLocation | RouteRecordNormalized
  ): boolean {
    if (!route.meta) {
      return false;
    }

    return (
      (route.meta.roles && (route.meta.roles as []).length > 0) ||
      (route.meta.permissions && (route.meta.permissions as []).length > 0)
    );
  }

  /**
   * Determines whether auth is required on the given route or not.
   *
   * @param route
   * @returns true if auth required
   */
  static isAuthRequired(route: RouteLocation): boolean {
    // tslint:disable-next-line:no-shadowed-variable
    return !route.matched.some(
      (route: RouteRecordNormalized) => route.meta?.authRequired === false
    );
  }

  /**
   * filter routes that are disabled while using keycloak
   * @param routes
   */
  static filterDisabledKeycloakRoutes(
    routes: RouteRecordRaw[]
  ): RouteRecordRaw[] {
    if (env('AUTH') !== 'keycloak') {
      return routes;
    }

    return routes.filter(
      // tslint:disable-next-line:trailing-comma
      (route: RouteRecordRaw) => !RouteHelper.isRouteDisabledWithKeycloak(route)
    );
  }

  /**
   * Determines whether route is disabled with keycloak set up
   *
   * @param route
   */
  static isRouteDisabledWithKeycloak(
    route: RouteRecordRaw | RouteLocation
  ): boolean {
    if (env('AUTH') !== 'keycloak') {
      return false;
    }

    return !!route.meta.disabledWithKeycloak;
  }

  /**
   * Filter out all routes which are not available for navigation
   *
   * @returns {RouteRecordRaw[]}
   */
  static getNavRoutes(
    userRoles: string[],
    userPermissions: string[] = []
  ): RouteRecordRaw[] {
    return RouteHelper.filterRoutes(appRoutes, userRoles, userPermissions);
  }

  /**
   * filters routes by their meta settings
   *
   * @param {RouteRecordRaw[]} routes
   * @returns {RouteRecordRaw[]}
   * @private
   */
  private static filterRoutes(
    routes: RouteRecordRaw[],
    userRoles: string[],
    userPermissions: string[] = []
  ): RouteRecordRaw[] {
    const result = [];

    routes.forEach((route) => {
      if (route.meta.hidden) {
        return;
      }

      if (
        RouteHelper.isRouteProtected(route) &&
        !RouteHelper.hasPermissionToAccess(route, userRoles, userPermissions)
      ) {
        return;
      }

      if (!route.children) {
        result.push(route);

        return;
      }

      route.children = RouteHelper.filterRoutes(
        route.children,
        userRoles,
        userPermissions
      );
      result.push(route);
    });

    return result;
  }

  /**
   * Converts unknown param to provided type
   * @param param
   * @param {ParamType} type
   * @param defaultValue
   * @returns {number | string}
   */
  static convertParam(
    param: unknown,
    type: ParamType,
    defaultValue = null
  ): number | string | null {
    switch (type) {
      case 'string':
        return RouteHelper.convertParamToString(param) || defaultValue;
      case 'number':
        return RouteHelper.convertParamToNumber(param) || defaultValue;
    }

    throw new Error(`Unknown type provided ${type}`);
  }

  /**
   * Converts unknown param if given to string
   * @param param
   * @returns {string | null}
   * @private
   */
  private static convertParamToString(param: unknown): string | null {
    if (!param) {
      return null;
    }

    const value = Array.isArray(param) ? param[0] : param;
    return value === null ? null : `${value}`;
  }

  /**
   * Converts unknown param if given to number
   * @param param
   * @returns {number | null}
   * @private
   */
  private static convertParamToNumber(param: unknown): number | null {
    if (!param) {
      return null;
    }

    let value = Array.isArray(param) ? param[0] : param;
    value = parseInt(value, 10);

    return isNaN(value) ? null : value;
  }
}
