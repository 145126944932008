import { SidebarLayout } from '@/components/layouts';
import { OutlineStatistics } from '@/components/icons/heroicons';
import { RouteRecordRaw } from 'vue-router';
import appOptions from '@/config/app.options';
import { hasRoleGuard } from '@/router/guards/hasRoleGuard';

export default [
  {
    path: '/admin/statistics',
    name: 'statistics',
    component: () => import('~/pages/admin/statistics.vue'),
    beforeEnter: [hasRoleGuard(appOptions.adminRoleNames)],
    meta: {
      layout: SidebarLayout,
      icon: OutlineStatistics,
      y: 'bottom',
      roles: appOptions.adminRoleNames,
    },
  },
  {
    path: '/statistics',
    name: 'statisticsUser',
    component: () => import('~/pages/statistics.vue'),
    meta: {
      layout: SidebarLayout,
      icon: OutlineStatistics,
      y: 'bottom',
    },
  },
] satisfies RouteRecordRaw[];
