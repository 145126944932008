import options from '@/config/app.options';
import { AuthService, tokenService } from '@/services';

export abstract class BaseAuthService implements AuthService {
  async init(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Initialize the token.
   */
  initToken(): void {
    this.setToken(this.getTokenResponse());
  }

  /**
   * if the user has been already authenticated
   * @returns {boolean}
   */
  abstract isAuthenticated(): boolean;

  /**
   * Login the user with the given credentials.
   *
   * @author Sascha Steinbrink <sascha@coding-pioneers.ocm>
   * @returns
   */
  abstract login(): void;

  /**
   * Loggout the current user by removing the tokens and also the bearer token.
   *
   * @author Sascha Steinbrink <sascha@coding-pioneers.ocm>
   * @returns
   */
  abstract logout(): Promise<void>;

  /**
   * Refresh the access token.
   *
   * @author Sascha Steinbrink <sascha@coding-pioneers.ocm>
   * @returns
   */
  abstract refreshToken(): Promise<void>;

  /**
   * Get the stored tokens as an token response.
   *
   * @returns token response
   */
  abstract getTokenResponse(): TokenResponse;

  /**
   * Set the token, the authorization headers and the not authorized interceptor.
   *
   * @param tokenResponse
   * @returns token
   */
  setToken(tokenResponse: TokenResponse): string {
    const { access_token, refresh_token, expires_in } = tokenResponse;

    tokenService.saveToken(access_token);
    tokenService.saveRefreshToken(refresh_token);
    tokenService.saveExpiresIn(expires_in);

    return access_token;
  }
}
