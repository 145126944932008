import { RouteRecordRaw } from 'vue-router';
import { EmptyLayout } from '@/components/layouts';

const routes: RouteRecordRaw[] = [
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/AppError.vue'),
    props: { errorCode: 404 },
    meta: {
      error: true,
      hidden: true,
      layout: EmptyLayout,
    },
  },
  {
    path: '/403',
    component: () => import('@/views/error/AppError.vue'),
    props: { errorCode: 403 },
    meta: {
      error: true,
      hidden: true,
      layout: EmptyLayout,
    },
  },
  {
    path: '/500',
    component: () => import('@/views/error/AppError.vue'),
    props: { errorCode: 500 },
    meta: {
      error: true,
      hidden: true,
      layout: EmptyLayout,
    },
  },
  {
    path: '/503',
    component: () => import('@/views/error/AppError.vue'),
    props: { errorCode: 503 },
    meta: {
      error: true,
      hidden: true,
      layout: EmptyLayout,
    },
  },
  {
    path: '/error/keycloak',
    component: () => import('@/views/error/KeycloakNotFound.vue'),
    meta: {
      error: true,
      hidden: true,
      layout: EmptyLayout,
    },
  },
  {
    path: '/error/authorization',
    component: () => import('@/views/error/AuthorizationError.vue'),
    meta: {
      error: true,
      hidden: true,
      layout: EmptyLayout,
    },
  },
];

export default routes;
