import ApplicationRoutes from './ApplicationRoutes';
import ErrorRoutes from './ErrorRoutes';
import AuthRoutes from './AuthRoutes';
import AdminRoutes from './AdminRoutes';

import TranslationMemoryRoutes from '@/modules/translationmemory/routes';
import GlossaryTermRoutes from '@/modules/glossary/term/routes';
import GlossaryRoutes from '@/modules/glossary/routes';
import { UserRoutes } from '@/modules/user/routes';

import routes from '~/routes';

export default [].concat(
  ErrorRoutes,
  AuthRoutes,
  ApplicationRoutes,
  TranslationMemoryRoutes,
  UserRoutes,
  AdminRoutes,
  GlossaryRoutes,
  GlossaryTermRoutes,
  routes
);

export {
  ErrorRoutes,
  AuthRoutes,
  ApplicationRoutes,
  TranslationMemoryRoutes,
  UserRoutes,
  AdminRoutes,
};
