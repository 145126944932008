import options from '@/config/app.options';
import datetimeFormats from '@/lang/dateTimeFormats';
import LocaleInitializer from '@/utils/locale/LocaleInitializer';
import { createI18n, IntlDateTimeFormats } from 'vue-i18n';

const i18n = createI18n<false>({
  // https://vue-i18n.intlify.dev/guide/advanced/composition.html#basic-usage
  legacy: false,
  locale: LocaleInitializer.getStartingLocale(),
  fallbackLocale: options.i18nConfig.fallbackLocale,
  messages: {}, // set locale messages,
  datetimeFormats: datetimeFormats as IntlDateTimeFormats,
  numberFormats: {
    de: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
      decimal: { style: 'decimal' },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'EUR',
        notation: 'standard',
      },
      decimal: { style: 'decimal' },
    },
  },
});

export const loadedLocales: string[] = [];
export default i18n;
