import { api } from '@/api';
import { env } from '@/utils';
import { AxiosResponse } from 'axios';

/**
 * The user service class that provides the API to deal with the user endpoint.
 */
export class UserService {
  /**
   * Get the current logged in user.
   *
   * @returns {Promise<AxiosResponse<User>>}
   */
  static current(): Promise<AxiosResponse<User>> {
    return api.get('/api/user/current');
  }

  /**
   * Get current users known in system with their roles
   * @param payload
   * @param withFilters
   */
  static list(
    payload: FetchPagePayload,
    withFilters = false
  ): Promise<
    AxiosResponse<
      | WithFilter<
          Pagination<User>,
          {
            languages: SelectableItem<string>[];
            roles: SelectableItem[];
          }
        >
      | Pagination<User>
    >
  > {
    return api.get('/api/user', {
      params: {
        ...payload.query,
        page: payload.page || 1,
        filters: withFilters,
        per_page: true,
      },
    });
  }

  /**
   * retrieve all users
   *
   * @returns {Promise<AxiosResponse<User[]>>}
   */
  static all(): Promise<AxiosResponse<User[]>> {
    return api.get(`/api/user?paged=0`);
  }

  /**
   * retrieve single user
   *
   * @param {number | string} id
   * @returns {Promise<AxiosResponse<User>>}
   */
  static single(
    id: number | string
  ): Promise<AxiosResponse<{ user: User; roles: SelectableItem[] }>> {
    return api.get(`/api/user/${id}?filters=1`);
  }

  /**
   * Create a new user.
   *
   * @param user
   * @returns {Promise<AxiosResponse<User>>}
   */
  static create(user: CreateUserRequest): Promise<AxiosResponse<User>> {
    return api.post(`/api/user/`, user);
  }

  /**
   * Updates user with the given id
   * @param user
   */
  static update(
    user: WithPasswordConfirmation<User>
  ): Promise<AxiosResponse<User>> {
    return api.postForm(`/api/user/${user.id}/update`, user, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  /**
   * Get all user settings
   * @param user
   */
  static allSettings(user: User): Promise<AxiosResponse<Setting[]>> {
    return api.get(`/api/user/${user.id}/settings`);
  }

  /**
   * retrieves single setting
   *
   * @param {User} user
   * @param settingKey
   * @returns {Promise<Setting | null>}
   */
  static async getSetting(
    user: User,
    settingKey: string
  ): Promise<Setting | null> {
    const settings = await UserService.allSettings(user);

    if (!settings) {
      return null;
    }

    let value = null;

    settings.data.forEach((setting) => {
      if (setting.key === settingKey) {
        value = setting.value;
      }
    });

    return value ?? null;
  }

  /**
   * Updates user with the given id
   * @param user
   * @param settings
   */
  static updateSettings(
    user: User,
    settings: Setting[]
  ): Promise<AxiosResponse<Setting[]>> {
    return api.put(`/api/user/${user.id}/settings`, { settings });
  }

  /**
   * Updates the password of the user with the given id
   * @param id
   * @param data
   */
  static updatePassword(
    id: number,
    data: unknown
  ): Promise<AxiosResponse<User>> {
    return api.put(`/api/${id}/update/password`, data);
  }

  /**
   * Updates the avatar of the user with the given id
   * The avatar file is inside the formData
   * @param id
   * @param formData
   */
  static updateAvatar(
    id: number,
    formData: FormData
  ): Promise<AxiosResponse<User>> {
    return api.post(`/api/user/${id}/avatar`, formData);
  }

  /**
   * Deletes user with the given id
   * @param user
   */
  static delete(
    user: WithPasswordConfirmation<User>
  ): Promise<AxiosResponse<void>> {
    if (env('AUTH') === 'keycloak') {
      return;
    }

    return api.put(`/api/user/${user.id}/delete`, user);
  }
}
