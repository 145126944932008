import { RouteRecordRaw } from 'vue-router';
import appOptions from '@/config/app.options';
import { RouteHelper } from '@/router/RouteHelper';
import { SidebarLayout } from '@/components/layouts';
import { ShieldLock } from '@/components/icons/tablericons';
import { AdminRoutes as UserAdminRoutes } from '@/modules/user/routes';
import { hasRoleGuard } from '@/router/guards/hasRoleGuard';

export default [
  ...UserAdminRoutes,
  {
    path: '/admin/roles',
    name: 'role_list',
    component: () => import('@/views/role/RoleList.vue'),
    props: ({ query }) => ({
      page: RouteHelper.convertParam(query.page, 'number', 1),
    }),
    beforeEnter: [hasRoleGuard(appOptions.adminRoleNames[0])],
    meta: {
      layout: SidebarLayout,
      icon: ShieldLock,
      y: 'bottom',
      roles: [appOptions.adminRoleNames[0]],
    },
  },
  {
    path: '/admin/roles/:id',
    name: 'role',
    component: () => import('@/views/role/RoleView.vue'),
    props: ({ params, query }) => ({
      id: RouteHelper.convertParam(params.id, 'number', null),
      page: RouteHelper.convertParam(query.page, 'number', 1),
    }),
    beforeEnter: [hasRoleGuard(appOptions.adminRoleNames[0])],
    meta: {
      layout: SidebarLayout,
      hidden: true,
      icon: ShieldLock,
      breadcrumbs: {
        parent: 'role_list',
      },
      roles: [appOptions.adminRoleNames[0]],
    },
  },
] satisfies RouteRecordRaw[];
