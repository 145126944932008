import '@/config/app.typed.env';
import '@/config/app.options';
import { createApp } from 'vue';
import App from '@/App.vue';
import { authService } from '@/services';
import pluginInstaller from '@/plugins';
import '@/assets/style/style.css';
import i18n from '@/lang';
import vfmPlugin from 'vue-final-modal';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import { createPinia } from 'pinia';
import { registerSentry } from '@/utils/SentryHelper';
import Sortable, { Swap } from 'sortablejs';
import '../node_modules/flowbite-vue/dist/index.css';
import '@vuepic/vue-datepicker/dist/main.css';

authService.init().then(async () => {
  const router = (await import('@/router')).default;

  const piniaStore = createPinia();
  Sortable.mount(new Swap());

  const app = createApp(App)
    .use(i18n)
    .use(pluginInstaller)
    .use(piniaStore)
    // eslint-disable-next-line
    // @ts-ignore
    .use(vfmPlugin)
    .use(registerSentry)
    .use(FloatingVue)
    .use(router)
    .mount('#app');
});
